import { template as template_3d54e79e6c8648eb8ed0bcfd3a41a53c } from "@ember/template-compiler";
const FKControlMenuContainer = template_3d54e79e6c8648eb8ed0bcfd3a41a53c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
