import { template as template_226e1d5570db43fba3ea3e89f796cb9e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_226e1d5570db43fba3ea3e89f796cb9e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
